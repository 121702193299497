import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/house-rules',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/payments',
    component: () => import('../pages/payments.vue'),  
  },
  {
    path: '/agents',
    component: () => import('../pages/agents.vue'),  
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})
export default router