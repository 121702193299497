<template>
  <div class="hamburger-menu">
    <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <span class="material-symbols-rounded header-content__icon">menu</span>
    </button>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span class="material-symbols-rounded">cancel</span></button>
      <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
        <a v-if="route.name === 'agents'" :href="`https://agents.${BackEndUrl}`" target="_blank">{{ route.name }}</a>
        <router-link v-else :to="route.route">{{ route.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  setup() {
      const BackEndUrl = "betmysport.com"
      return {
      BackEndUrl
            }
    },
  data() {
    return {
      isActive: false,
      routes: [
        {
          name       :  'home',
          route      :  '/'
        },
        {
          name       :  'house rules',
          route      :  'house-rules'
        },
        {
          name       :  'agents',
        },
        {
          name       :  'payments',
          route      :  'payments'
        }
      ]
    };

  },
};
</script>
