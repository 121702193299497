    <template>
        <div class="menu--desktop">
        <button v-for="(button, index) in buttons" :key="index" :class="'btn ' + button.class" @click="navigateTo(button.route)">
            {{ button.text }}
        </button>
        <agentButton />
        </div>
    </template>
    
    <script>
import agentButton from './agent-button.vue';
    export default {
        name: "MenuDesktop",
        components: {
            agentButton,
    },
        data() {
        return {
            buttons: [
                {
                    text: 'HOME', 
                    class: 'btn-primary',
                    route: '/' 

                },
                { 
                    text: 'HOUSE RULES', 
                    class: 'btn-primary', 
                    route: '/house-rules' 

                },
                { 
                    text: 'PAYMENTS', 
                    class: 'btn-primary', 
                    route: '/payments' 
                }
            ]
        };
        },
        methods: {
        navigateTo(route) {
            this.$router.push(route);
        }
        }
    };
    </script>
    