<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <menu-component/>
        <div class="header-content__nav--img">
          <a href="/">
            <img class="header-content__img" src="../assets/images/logo-betmysports.png" alt="bet my sports" />
          </a>
        </div>
      </div>
      <div class="header-content__cta">
          <form class="form-login form-desktop mt-4" method="post" :action="`//wager2.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <div class="mb-3 form-inputs">
            <input type="text" class="form-control" placeholder="User" name="account" id="account" required>
            <input type="password" class="form-control" placeholder="Password" name="password" id="password" required>
            </div>
            <button class="btn btn-primary" type="submit">LOGIN</button>
          </form> 
      <!-- Login mobile -->
      <form class="form-login form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
        <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
        <div class="mb-3 form-inputs">
        <input type="text" class="form-control" placeholder="User" name="account" id="account" required>
        <input type="password" class="form-control" placeholder="Password" name="password" id="password" required>
        </div>
        <button class="btn btn-primary" type="submit">LOGIN</button>
      </form>
      <div class="header-content__phone">
        <p class="mb-2">APUESTAS 01-800-681-5357</p>
      </div>
      </div>
    </div>
  </header>
  <menu-desktop/>
</template>

<script>
  import MenuComponent from '@/components/menu.vue'
import MenuDesktop from './menu-desktop.vue';

  export default {
    name: "HeaderComponent",
    components: {
      MenuComponent,
        MenuDesktop
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
    setup() {
      const backendUrl = "betmysport.com";
      const DGS_SITEID = 230;
      
      return {
        backendUrl,
        DGS_SITEID
      }
    },
  };
</script>
